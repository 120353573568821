import React, { Component } from "react";
import { Post, Get } from "../../../utilities/axiosRequest";
import { convertDate } from "../../Components/Common/Common";
import Tooltips from "../../Components/Tooltips/Tooltips";
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Table,
    Badge,

    PaginationItem,
    PaginationLink,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowModalData from "./ShowModalData";
import { maintenanceData } from "./Data";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import Pagination from "react-js-pagination";
import axios from 'axios';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
const device = [
    { value: "Mobile", label: "Mobile" }
];
const filter = [
    { label: "Country" },
    { label: "City" },
    { label: "Category" },
    { label: "Company Name" }
]
const divice_fixes = [
    {
        id: 7,
        label: "لن يتم الإصلاح"
    },
    {
        id: 8,
        label: "إصلاح وانتهاء"
    },
    {
        id: 9,
        label: "عمل معلق"
    }
];

const complaint_filter = [
    {
        value: 1,
        label: "Yes"
    },
    {
        value: 2,
        label: "No"
    },
    {
        value: "",
        label: "All"
    }

];

class Maintenance extends Component {

    state = {
        isShowFilterModal: false,
        filteredBy: "",
        filteredBy1: "",
        selectedRecordInfo: null,
        deviceValue: 1,
        startingDate: "",
        endingDate: "",
        sDate: "",
        eDate: "",
        customerId: "",
        deviceId: "",
        customerName: "",
        mobileNumber: "",
        maintenanceRecords: [],
        device: [],
        activePage: 1,
        page: 1,
        itemsCountPerPag: 1,
        totalItemsCount: 1,
        country_name: '',
        city_name: '',
        store_id: '',
        complaint_or_not: '',
        fixingstatus: [],
        devicestatus: '',
        device_return: '',
        device_fixed: ''


    }

    dateChangeHandler = (date, name) => {

        this.setState({ [name]: date });

    }
    valueChangeHandler = ({ target: { value, name } }) => {
        console.log(name, value)
        this.setState({ [name]: value })

    }
    infoHandler = (selectedInfo) => {
        this.setState({ selectedRecordInfo: selectedInfo })
    }
    modalTogglerHandler = () => {
        this.setState({ selectedRecordInfo: null });
    }

    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber });
        this.setState({ page: pageNumber });


        axios.get('https://mithakh.com/backend/api/v1/store/searchmaintanancereports', {
            params: this.state
        }, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(resp => {
            console.log("Response pagination", resp.data.fixinginfo.data)
            this.setState({ maintenanceRecords: resp.data.fixinginfo.data, totalItemsCount: resp.data.total });

        })


    }

    searchRecords = () => {
        const { startingDate, endingDate, deviceValue, customerId, customerName, deviceId, mobileNumber, devicestatus, deveice_return, device_fix, complaint_or_not } = this.state;
        console.log("States..", this.state)
        axios.get('https://mithakh.com/backend/api/v1/store/searchmaintanancereports', {
            params: this.state
        }).then(resp => {
            console.log("Fixing info", resp);
            this.setState({ maintenanceRecords: resp.data.fixinginfo.data, totalItemsCount: resp.data.total });

        }

        );
    }

    componentDidMount() {
        const status = [];

        const service = [];
        const store_id = localStorage.getItem("store_user_id");
        Get(`https://mithakh.com/backend/api/v1/store/more-info?&store_id=${store_id}`).then(result => {

            if (result.storeinfo.length > 0) {
                this.setState({ country_name: result.storeinfo[0].country_name, city_name: result.storeinfo[0].city_name });
            }
        })


        const default_city = 1;
        service.push({ 'value': '', 'label': 'All' });
        Get(`https://mithakh.com/backend/api/v1/services?&city_id=${default_city}`).then(result => {
            if (result.cityservices) result.cityservices.forEach(function (data, idx) {
                service.push({ 'value': data.service_id, 'label': data.service_title });
            });

            this.setState({ device: service });
            console.log("Services...", result.cityservices)
        });

        status.push({ 'value': '', 'label': 'All' });
        // Get(`https://mithakh.com/backend/api/v1/store/fixing-status`).then(result => this.setState({fixingstatus:result.fixingstatus})); 
        Get("https://mithakh.com/backend/api/v1/store/fixing-status").then(result => {
            result.fixingstatus.forEach(function (data, idx) {
                status.push({ 'value': data.req_status_id, 'label': data.title });
            });

            this.setState({ fixingstatus: status });
        });

        Get('https://mithakh.com/backend/api/v1/store/maintanancereports', {
            params: this.state
        }).then(resp => {
            console.log("Fixing info", resp);
            this.setState({ maintenanceRecords: resp.fixinginfo.data, totalItemsCount: resp.total, store_id: resp.store_id });

        }

        );

    }

    render() {
        const { t } = this.props;
        const {
            deviceValue,
            maintenanceRecords,
            selectedRecordInfo,
            startingDate,
            endingDate,
            customerId,
            deviceId,
            customerName,
            filteredBy,
            filteredBy1,
            mobileNumber,
            country_name,
            city_name,
            isShowFilterModal, device, totalItemsCount, complaint_or_not, fixingstatus, devicestatus, device_return, device_fixed } = this.state;

        console.log("state in....maintanance report", maintenanceRecords)
        console.log("pagenumber", this.state.activePage)
        return (
            <>
                {
                    selectedRecordInfo ?
                        <Modal isOpen={selectedRecordInfo ? true : false} backdrop="static" className="modalStyle">
                            <ModalHeader toggle={this.modalTogglerHandler}>{t('Device Information')}</ModalHeader>
                            <ModalBody>
                                <ShowModalData selectedRecord={selectedRecordInfo} />
                            </ModalBody>
                        </Modal> : null
                }
                <Modal isOpen={isShowFilterModal} className="filterModalStyle" backdrop="static">
                    <ModalHeader toggle={() => this.setState({ isShowFilterModal: false })}>{t('Filtered By')}</ModalHeader>
                    <ModalBody >
                        <Row>
                            {filter.map(item => (
                                <Col key={item.label} xs="12" sm="6" md="6" className="d-flex align-items-center justify-content-center">
                                    <CustomCheckBoxLabel
                                        label={item.label}
                                        active={filteredBy}
                                        groupName="filteredBy"
                                        extraStyle="mx-2 flex-grow-1"
                                        onChange={this.valueChangeHandler}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </ModalBody>
                </Modal>
                {/*<div className="d-flex d-md-none mb-4 justify-content-center">
                    <div className="d-flex align-items-center BoxShadow px-2" onClick={() => this.setState({ isShowFilterModal: true })} style={{ width: 'fit-content', borderRadius: 50 }}>
                        <i className="fa fa-sliders mx-1"></i>
                        <span className="mx-1">{t('Filters')}</span>
                        <Badge color="warning" className="mx-1 rounded-circle text-light">3</Badge>
                    </div>
                </div>*/}
                <Col md="10" xs="12" className="mb-2 pl-0">
                    <Row className="mt-1 mb-3">

                        <Col md="2" className="mb-2 pr-0">
                            <Row>
                                <Col xs='4'>
                                    <span className="font-weight-bold" style={{ lineHeight: '2.5em' }}>From</span>
                                </Col>
                                <Col xs='8  ' className="pr-0 pl-0">
                                    <DatePicker
                                        selected={startingDate}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => this.dateChangeHandler(date, "startingDate")}
                                        placeholderText={t('start date')}
                                        className="BoxShadow px-2 datePicker-1" />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="2" className="mb-2 pr-1 pl-0">
                            <Row>
                                <Col xs='3' className="pl-0" style={{ textAlign: 'center' }}
                                >
                                    <span className="font-weight-bold" style={{ lineHeight: '2.5em', textAlign: 'center' }}>To</span>
                                </Col>
                                <Col xs='8' className="pr-0">
                                    <DatePicker
                                        selected={endingDate}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => this.dateChangeHandler(date, "endingDate")}
                                        placeholderText={t('end date')}
                                        className="BoxShadow px-2 datePicker-1" />
                                </Col>
                            </Row>
                        </Col>


                        <Col sm="2" className="pr-0">
                            <Input
                                className="BoxShadow mb-2"
                                type="text"
                                value={deviceId}
                                onChange={this.valueChangeHandler}
                                placeholder={t('Request Id')}
                                name="deviceId" />
                        </Col>
                        <Col sm="2" className="pr-0">
                            <Input
                                type="text"
                                name="customerName"
                                value={customerName}
                                onChange={this.valueChangeHandler}
                                placeholder={t('Customer Name')}
                                className="BoxShadow" />
                        </Col>
                        <Col sm="2" className="pr-0">

                            <Select
                                className="BoxShadow mb-2"
                                value={device.find(p => p.value === deviceValue)}
                                onChange={(value) => this.setState({ deviceValue: value.value })}
                                placeholder={t('All Device')}
                                name="device"
                                options={device} />

                        </Col>

                        <Col sm="2" className="pr-0">
                            <Input
                                type="text"
                                value={customerId}
                                name="customerId"
                                onChange={this.valueChangeHandler}
                                placeholder={t('Customer Id')}
                                className="BoxShadow" />
                        </Col>

                    </Row>

                    <Row className="mt-1 mb-3">

                        <Col sm="2" className="mb-2" >
                            <span>Mobile</span>
                            <Input
                                name="mobileNumber"
                                value={mobileNumber}
                                onChange={this.valueChangeHandler}
                                type="text"
                                placeholder={t('Mobile Number')}
                                className="BoxShadow" />
                        </Col>

                        <Col sm="2" className="pr-0">
                            <span>Complaint or not</span>
                            <Select
                                className="BoxShadow mb-2"
                                value={complaint_filter.find(p => p.value === complaint_or_not)}
                                onChange={(value) => this.setState({ complaint_or_not: value.value })}
                                placeholder={t('Complaint')}
                                name="complaint_or_not"
                                options={complaint_filter} />
                        </Col>

                        <Col sm="2" className="pr-0">
                            <span>Device Status</span>
                            <Select
                                className="BoxShadow mb-2"
                                value={complaint_filter.find(p => p.value === devicestatus)}
                                onChange={(value) => this.setState({ devicestatus: value.value })}
                                placeholder={t('Status')}
                                name="devicestatus"
                                options={fixingstatus} />

                        </Col>
                        <Col sm="2" className="pr-0">
                            <span>Return</span>
                            <Select
                                className="BoxShadow mb-2"
                                value={complaint_filter.find(p => p.value === device_return)}
                                onChange={(value) => this.setState({ device_return: value.value })}
                                placeholder={t('Device Return')}
                                name="device_return"
                                options={complaint_filter} />

                        </Col>
                        <Col sm="2" className="pr-0">
                            <span>Fixed</span>
                            <Select
                                className="BoxShadow mb-2"
                                value={complaint_filter.find(p => p.value === device_fixed)}
                                onChange={(value) => this.setState({ device_fixed: value.value })}
                                placeholder={t('Device Fixed')}
                                name="device_fixed"
                                options={complaint_filter} />

                        </Col>
                        <Col sm="2" className="text-center  pr-0" style={{ lineHeight: '6em' }}>
                            <Button
                                className="RequestBtn px-4 font-weight-bolder"
                                onClick={this.searchRecords}>{t('search')}</Button>
                        </Col>
                    </Row>




                    <Row>
                        <Col sm="12" className="text-center mb-3 d-flex ml-2">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <span>{country_name}</span> &nbsp;&nbsp; | &nbsp;&nbsp;
                            <span>{city_name}</span>
                        </Col>

                    </Row>

                    <div className="mb-4">
                        <Table className="mb-2 table-responsive-md" style={{ borderBottom: "1px solid #ff5900" }}>
                            <thead>
                                <tr style={{ backgroundColor: "#73C0F1", color: "white" }}>
                                    <th>{t('Device Id')}</th>
                                    <th>{t('Type')}</th>
                                    <th>{t('Customer Name')}</th>
                                    <th>{t('Customer Mobil')}</th>
                                    <th>{t('Status')}</th>
                                    <th>{t('Return')}</th>
                                    <th>{t('Fix')}</th>
                                    <th>{t('complaint')}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    maintenanceRecords.map((p, ind) => (
                                        <tr key={ind} onClick={() => this.infoHandler(p)}>
                                            <td>{p.request_id}</td>
                                            <td>{p.service_title_ar}</td>
                                            <td>{p.customer_name}</td>
                                            <td>{p.customer_mobile1 ? p.customer_mobile1 : p.customer_mob}</td>
                                            <td>
                                                {p.title}

                                            </td>
                                            <td>{p.device_status_id == 13 ? 'Yes' : "Not"}</td>
                                            <td>{p.device_status_id == 8 ? 'Yes' : "Not"}</td>



                                            <td><Tooltips msg={p.customer_msg} /></td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={5}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}
                        />




                    </div>
                </Col>
            </>
        );
    }
}

export default withTranslation()(Maintenance);