import React, { Component } from "react";
import {
    Container, Row, Col, Label, Input, Button, InputGroup, FormGroup, FormFeedback, Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import sideImage from "../../../assets/images/Group 426.png"
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import Footer from "../../Components/Footer";
import { Post, Get } from "../../../utilities/axiosRequest";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import ToastBox from "../../Components/ToastBox/ToastBox";
import i18next from 'i18next';
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
const validationSchema = Yup.object().shape({
    /** emailAddress: Yup.string()
            .required("E-mail or username is required"),
     password: Yup.string()
         .trim("No white space allow in starting")
         .required("Password is required")**/
});

class ResetPassword extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    state = {
        showPassword: false,
        customer: this.props.cookies.get("user") || "",
        isShowForgotModal: false,
        show: false,
        message: '',
    }

    changePassword = (values) => {

        const { cookies } = this.props;
        const data = {
            newpassword: values.newpassword,
            confirmpassword: values.confirmpassword,
            rtoken: values.rtoken
        };


        const options = {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        };
        axios.post('https://mithakh.com/backend/api/v1/user/resetPassword', data, options)
            .then(result => {

                if (result.data.result) {


                    this.setState({ message: 'Your password has been changed', show: true })
                    return false;
                } else {
                    this.setState({ message: 'Something wrong', show: true })
                    return false;
                }


                console.log("Result...", result);
                /** if( result.data.accessToken==undefined){
                 this.setState({message:result.data.message})
                     this.props.history.replace("/login");
                 
                 }else{
                   localStorage.setItem("token", result.data.accessToken)
                   localStorage.setItem("firstname",result.data.firstName)
                   localStorage.setItem("mobile",result.data.mobile)
                   localStorage.setItem("customer_id",result.data.customer_id)
     
                   cookies.set("customer",result.data.user.id, { path: "/" ,expires: new Date(Date.now()+2592000)}); 
                   
                  cookies.set("user_type_id",result.data.user.user_type_id, { path: "/" ,expires: new Date(Date.now()+2592000)}); 
     
                  
                      if(result.data.user.user_type_id==2 ){
                         this.props.history.replace("/company-home");
                      }else if(result.data.user.user_type_id==1 ){
                         this.props.history.replace("/");
                     }else if(result.data.user.user_type_id==3 ){
                         this.props.history.replace("/driver-home");
     
                      }else if(result.data.user.user_type_id==100 ){
                         this.props.history.replace("/admin-home");
     
                      }
                   
                 }**/
            });


    }

    forgotPassword = () => {
        //alert("Coming Soon...");
        this.setState({ isShowForgotModal: true });
    }
    changeUsername = () => {


    }

    handleChangeText = e => {
        console.log(e);
        this.setState({ emailAddress: e.target.value });
        //this.props.onChange(e);
    };
    sendForgot = () => {

        const data = {
            emailAddress: this.state.emailAddress,

        };
        const options = {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        };
        axios.post('https://mithakh.com/backend/api/v1/user/forgot-password', data)
            .then(result => {

                console.log("result", result)
            })


    }

    setShow = (status) => {
        this.setState({ show: status });

    }

    render() {
        const { t } = this.props;
        console.log("props", this.props.match.params.rtoken)
        const { showPassword, message, isShowForgotModal, show } = this.state;
        return (
            <>
                <ToastBox show={show} message={message} setShow={this.setShow} />
                <Modal isOpen={isShowForgotModal} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ isShowForgotModal: false })}>Forgot Password?</ModalHeader>
                    <ModalBody >
                        <Row>

                            <Col xs="12" className="mb-4 d-flex">
                                <Label>{t('Email Address')}</Label>
                                <FormGroup>
                                    <Input

                                        type="text"
                                        value={this.state.emailAddress}
                                        name="emailAddress"
                                        onChange={this.handleChangeText}
                                        onBlur=""
                                        placeholder={t('Email Address')}
                                        className="BoxShadow pl-4" />

                                </FormGroup>
                            </Col>
                            <Col xs="12" className="text-center mt-1">
                                <Button className="RequestBtn px-4 font-weight-bolder" onClick={this.sendForgot} >{t('Send')}</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <Container>
                    <Row className="loginPage">
                        <Col md="6" className="align-self-center">

                            <h3 className="d-flex" style={{ color: "#FF5900" }}>{t('Reset Password')}</h3>
                            {message !== "" &&

                                <p className="m-0 text-muted text-center text-dark-red">
                                    {message

                                    }

                                </p>
                            }
                            <Formik
                                initialValues={{
                                    newpassword: "",
                                    confirmpassword: "",
                                    rtoken: this.props.match.params.rtoken,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={
                                    values => (this.changePassword(values))
                                }>
                                {({
                                    errors,
                                    values,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                }) => (
                                    <form>
                                        <Row className="mt-2">
                                            <Col xs="12" className="mb-4">
                                                <Label className="d-flex">{t('New Password')}</Label>
                                                <FormGroup>
                                                    <Input
                                                        invalid={errors.newpassword && touched.newpassword}
                                                        type="password"
                                                        value={values.newpassword}
                                                        name="newpassword"
                                                        onChange={handleChange("newpassword")}
                                                        onBlur={handleBlur("newpassword")}
                                                        placeholder={t('New Password')}
                                                        className="BoxShadow pl-4" />
                                                    <FormFeedback>{touched.newpassword && errors.newpassword}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" >
                                                <Label className="d-flex">{t('Confirm Password')}</Label>
                                                <FormGroup>
                                                    <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.confirmpassword && touched.confirmpassword })}>
                                                        <Input
                                                            invalid={errors.confirmpassword && touched.confirmpassword}
                                                            autoComplete="off"
                                                            type={showPassword ? "text" : "password"}
                                                            value={values.confirmpassword.trim()}
                                                            name="confirmpassword"
                                                            onChange={handleChange("confirmpassword")}
                                                            onBlur={handleBlur("confirmpassword")}
                                                            placeholder={t('confirmPassword')}
                                                            className="pl-4 border-0 shadow-none" />
                                                        <i
                                                            className={classnames("passwordHideShow d-flex align-items-center pr-4", { "fa fa-eye-slash": !showPassword, "fa fa-eye": showPassword })}
                                                            onClick={() => this.setState((prev) => ({ showPassword: !prev.showPassword }))}></i>
                                                    </InputGroup>
                                                    <FormFeedback>{touched.confirmpassword && errors.confirmpassword}</FormFeedback>
                                                </FormGroup>
                                                <input type="hidden" name="rtoken" id="rtoken" value={this.props.match.params.rtoken} />
                                            </Col>

                                            <Col xs="12" className="text-center mt-3">
                                                <Button className="loginBtn px-4 font-weight-bolder" onClick={handleSubmit}>{t('Change')}</Button>
                                            </Col>

                                        </Row>
                                    </form>
                                )}
                            </Formik>
                        </Col>
                        <Col md="6" className="d-none d-md-block align-self-center">
                            <img src={sideImage} alt="" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
};

export default withTranslation()(withCookies(ResetPassword));