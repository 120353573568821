import React, { Component } from "react";
import {
  Container, Row, Col, Button, Modal,
  ModalBody,
  ModalHeader

} from "reactstrap";
import { Toast } from 'react-bootstrap';
import "./Home.css";
import sdeImage from "../../../assets/images/Group 426.png";
import ajaxLoaderN from "../../../assets/images/ajax-loader.gif";
import "react-datepicker/dist/react-datepicker.css";
import ChooseServices from "./ChooseServices";
import GetInfo from "./GetInfo";
import Footer from "../../Components/Footer";
import GetInfo2 from "./GetInfo2";
import { Post, Get } from "../../../utilities/axiosRequest";

import axios from 'axios';
import i18next from 'i18next';
import SocialMediaButtons from "../../Components/ShareButton/SocialMediaButtons";
import { withCookies, Cookies } from "react-cookie";


import { instanceOf } from "prop-types";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.setState({
      defaultLanguage: props.sellanguage
    });



  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  state = {
    city: "",
    message: "",
    message1: "",
    message2: "",
    message3: "",
    country: "",
    clarify: "",
    deviceModel: "",
    mobileNumber: "",
    //deviceType: "Mobile",
    deviceType: "",
    selectedService: "",
    tasktypes: [],
    serviceType: "Fix",
    taskType: "",
    sourceRequest: "web",
    // demandType: "Urgent",
    // contactType: "Mobile",
    isShow: "chooseService",
    // selectedDate: "",
    country: [],
    city: [],
    services: [],
    lat: '21.4249',
    lng: '39.850',
    customer_id: '',
    country_id: 1,
    city_id: 10,
    imagedata: '',
    captchaVal: '',
    spinnerLoading: false,
    show: false,
    showModal: false,
    tasks: [],
    task: '',
    selecttask: '',
    delivery_title: '',
    fileSizeError: '',
    otpMsg: 'Please fill otp',
    otpresult: false,
    token: '',
    showExistOTP: false,
    resendmessage1: '',
    country_code: '+212',
    support_number: '676437169',
    isShowShare: false,
    defaultLang: '39.850',
    defaultLat: '21.4249',
    mapSel: false,
    ajaxLoader: false,
    defaultLanguage: 'ar',

  }
  setShow = (status) => {
    this.setState({ show: status });

  }
  togglePop = () => {

    this.setState({
      showModal: !this.state.showModal
    });
  };
  checkBoxChangeHandler = ({ target: { value, name } }) => {
    this.setState({ [name]: value });

  }


  changearabicToEng = (inputNumber2) => {
    console.log("inputNumber2", inputNumber2)

    if (inputNumber2 == undefined) return '';
    var str = inputNumber2.toString().trim();
    if (str == "") return "";
    str = str.replace(/۰/g, '0');
    str = str.replace(/۱/g, '1');
    str = str.replace(/۲/g, '2');
    str = str.replace(/۳/g, '3');
    str = str.replace(/۴/g, '4');
    str = str.replace(/۵/g, '5');
    str = str.replace(/۶/g, '6');
    str = str.replace(/۷/g, '7');
    str = str.replace(/۸/g, '8');
    str = str.replace(/۹/g, '9');
    console.log("striii", str)
    this.setState({ mobileNumber: str })
    return str;
  }

  changearabicToEng = (str) => {
    const persianNumbers = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"]
    const arabicNumbers = ["١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "٠"]
    const englishNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]

    str = str.split("").map(c => englishNumbers[persianNumbers.indexOf(c)] ||
      englishNumbers[arabicNumbers.indexOf(c)] || c).join("")
    this.setState({ mobileNumber: str })
    return str;
  };


  changeTaskType = (value, task_id) => {
    this.setState({ selecttask: value });
    const country_id = this.state.country_id;


    Get(`https://mithakh.com/backend/api/v1/get-delivery-title?&country_id=${country_id}&task_id=${task_id}`).then(result => {

      console.log("delivery_title", result.delivery_title)
      this.setState({
        delivery_title: i18next.language == "en" ? result.delivery_title : i18next.language == "ar" ? result.delivery_title_ar : result.delivery_title_fr
      })
    }

    );

  }

  checkBoxChangeServiceHandler = ({ target: { value, name, id } }) => {
    this.setState({ [name]: value });
    this.setState({ selectedService: id });

    var today = new Date()
    const hrs = today.getHours();
    console.log("hrs..", hrs)
    Get(`https://mithakh.com/backend/api/v1/get-tasks?&service_id=${value}&country_id=${this.state.country_id}&city_id=${this.state.city_id
      }`).then(result => this.setState({ tasks: result.tasktypes }));
    if (hrs >= 20 || hrs < 8) {

      this.setState({ message2: i18next.language == "en" ? 'Our working hours are from 8:00 AM until 08:00 PM, and we will contact you later  complete your service' : i18next.language == "ar" ? "ساعات العمل لدينا من الساعة 8:00 صباحا حتى 08:00  مساء. هل ترغب بالاستمرار وسوف نتصل بك لاحقا لإكمال خدمتك " : "Horaires de travail de 8 h 00 à 20 h 00. Voulez-vous continuer et nous vous contacterons plus tard pour compléter votre service" })
      this.setState({ show: true });
      return false;
    }




    this.changeComponent("gettingInfo1");
  }
  updateLoctaionCord = (lat, lng) => {
    this.setState({ lat: lat, lng: lng, mapSel: true });


  }


  closeToastPopUp = () => {
    this.setState({ isShow: 'chooseService', selecttask: '', message1: '', message2: '', message3: '', message4: '' });
    this.setShow(false);
  };

  continueExe = () => {
    this.setState({ isShow: 'gettingInfo1', message2: '' });
    this.setShow(false);
    //this.changeComponent('gettingInfo1')

  };
  changeComponent = (componentName) => {
    this.setState({ isShow: componentName, selecttask: '' });
    const tasktypes = this.loadTaskTypes();

  }

  onCaptchaChange = (value) => {
    this.setState({ captchaVal: value });


  }
  verifyOTP = (value) => {

    axios.get(`https://mithakh.com/backend/api/v1/otp-verify?&otp_code=${value}`).then(result => { console.log(" result.data.otpresult", result.data.otpresult); this.setState({ otpresult: result.data.otpresult }) });


  }

  filterServices = (selected, setFieldValue) => {
    console.log("dddddd", selected)
    setFieldValue('city_id', selected);
    this.setState({ city_id: selected, ajaxLoader: true });
    Get(`https://mithakh.com/backend/api/v1/services?&city_id=${selected}`).then(result => {
      if (result.cityservices.length > 0) { console.log("city servissssces", result.cityservices[0].loc_lang); this.setState({ services: result.cityservices, ajaxLoader: false, support_number: result.cityservices[0].support_number, lat: result.cityservices[0].loc_lat, lng: result.cityservices[0].loc_lang }) } else {
        this.setState({ services: result.cityservices, ajaxLoader: false, support_number: result.cityservices[0].support_number })
      }
    }
    );
  }


  filterCities = (selected, setFieldValue) => {
    const cities = [];
    setFieldValue('country_id', selected);
    console.log("selectreedc ciountry...", selected);
    this.setState({ country_id: selected });
    Get(`https://mithakh.com/backend/api/v1/country-info?&country_id=${selected}`).then(result => {

      this.setState({ country_code: result.countryinfo[0].country_code })
    }
    );

    Get(`https://mithakh.com/backend/api/v1/cities?&country_id=${selected}`).then(result => {
      result.cities.forEach(function (data, idx) {
        cities.push({
          'value': data.city_id, 'label': i18next.language == "en" ? data.city_name : i18next.language == "ar" ? data.city_name_ar : data.city_name_fr
        });
      });

      this.setState({ city: cities, city_id: cities[0].value })
      this.filterServices(cities[0].value, setFieldValue)
    }
    );


    // Get(`https://mithakh.com/backend/api/v1/services?&city_id=${selected}`).then(result => this.setState({services: result.cityservices }));  

  }


  sendOTP = () => {

    const mobileNumber = this.state.mobileNumber;
    console.log("mobile number", mobileNumber)
    axios.post('https://mithakh.com/backend/api/v1/send-otp', { mobileNumber: mobileNumber }).then(result => {
      console.log("otp status", result)

      if (result.data.result == "exist") {
        this.setState({ showExistOTP: true })
      } else {
        this.setState({ showExistOTP: false })
      }

    });
    this.changeComponent('gettingInfo2')
  }

  reSendOTP = () => {
    this.setState({ resendmessage1: "تم إرسال الرقم إلى جوالك" })
    const mobileNumber = this.state.mobileNumber;
    console.log("mobile number", mobileNumber)
    axios.post('https://mithakh.com/backend/api/v1/send-otp', { mobileNumber: mobileNumber, resend: 1 }).then(result => {
      //console.log("Resend...",result)
      this.setState({ resendmessage1: "تم إرسال الرقم إلى جوالك" })
      if (result.data.result == "succsess") {


      }

    })
  }


  gettingData = (values) => {
    console.log(values)
    this.setState(prev => ({
      ...prev,
      ...values
    }));
  };

  onFileChange = (file) => {
    const size = file[0].size / 1024 / 1024;
    if (size > 2) {

      this.setState({
        fileSizeError: 'Not allowed file size more than 2MB'
      })
    } else {
      this.setState({
        fileSizeError: ''
      })

    }
    this.setState({
      imagedata: file[0],
    })
  }
  //Form Submissio
  /**  onFileChange = file => { 
         console.log("File...",file[0])
         this.setState({  imagedata: file[0] }); 
      
     }; 
      **/
  handleChange(file) {
    this.setState({
      imagedata: file[0],
    })
  }

  onFileUpload = (values) => {
    const formData = new FormData();
    console.log("formData", formData);
    // Update the formData object 
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    // Details of the uploaded file 
    console.log("ddds", this.state.selectedFile);
    console.log("formData", formData);
    // Request made to the backend api 
    // Send formData object 

    Post(`https://mithakh.com/backend/api/v1/user/uploadfile`, formData).then(result => console.log("result....", result));
  };

  sendRequestForm = (values) => {


    this.setState({ secretkey: '6Lcw8fUZAAAAAMzaUHOKtYW90oaCtZ_PlTeltYjY' });
    this.setState({ spinnerLoading: true });
    const fd = new FormData();
    fd.append('image', this.state.imagedata);

    fd.append('data', JSON.stringify(this.state));
    console.log("fd....", fd)
    axios({
      method: 'post',
      url: 'https://mithakh.com/backend/api/v1/user/create-request',
      data: fd,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(result => {
        if (result.data.result == "success") {
          this.setState({ spinnerLoading: false });
          this.setState({ show: true });
          console.log("response", result)
          var today = new Date()
          const hrs = today.getHours();
          console.log("hrs..", hrs)
          if (hrs >= 20 || hrs < 8) {
            this.setState({ message2: i18next.language == "en" ? 'Our working hours are from 8:00 AM until 08:00 PM, and we will contact you later  complete your service' : i18next.language == "ar" ? "ساعات العمل لدينا من الساعة 8:00 صباحا حتى 08:00  مساء. هل ترغب بالاستمرار وسوف نتصل بك لاحقا لإكمال خدمتك " : "Horaires de travail de 8 h 00 à  h 00" })
          } else {

            this.setState({ message2: i18next.language == "en" ? 'We will contact you immediately' : i18next.language == "ar" ? "سوف يتم التواصل معك عاجلا" : "Nous vous contacterons bientôt" })
          }
          this.setState({ message4: i18next.language == "en" ? 'We will contact you immediately' : i18next.language == "ar" ? "سوف يتم التواصل معك عاجلا" : "Nous vous contacterons bientôt" })
          this.setState({ message1: i18next.language == "en" ? 'Sent Succesfully' : i18next.language == "ar" ? "تم الإرسال بنجاح " : "Envoyé avec succès" })
          this.setState({ message3: i18next.language == "en" ? 'Request id :' + result.data.request_id : i18next.language == "ar" ? 'رقم الطلب :' + result.data.request_id : "Numéro de commande :" + result.data.request_id })

          this.setState({ showExistOTP: false })


        } else {
          this.setState({ show: true });
          console.log("response", result)
          this.setState({ message1: result.data.message })
          this.setState({ spinnerLoading: false });

        }


      });


  };




  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: URL.createObjectURL(file)
      })
    };

    reader.readAsDataURL(file);
  }

  componentWillUnmount() {
    //this.timer = null; // here...
  }
  componentDidUpdate(prevProps) {
    console.log("Current..", this.props)
    console.log("Prev..Props..", prevProps);
    const countries = [];
    const cities = [];
    const selLang = this.props.sellanguage;
    if (prevProps.sellanguage != this.props.sellanguage) {
      axios.get("https://mithakh.com/backend/api/v1/countries_web", { headers: { 'Access-Control-Allow-Origin': '*' } }).then(result => {
        console.log("country..", result); result.data.countries.forEach(function (data, idx) {
          countries.push({ 'value': data.country_id, 'label': selLang == "en" ? data.country_name : selLang == "ar" ? data.country_name_ar : data.country_name_fr });
        });

        this.setState({ country: countries });
      });
      /* axios.get("https://mithakh.com/backend/api/v1/cities", { headers: { 'Access-Control-Allow-Origin': '*' } }).then(result => {
         result.data.cities.forEach(function (data, idx) {
           cities.push({ 'value': data.city_id, 'label': selLang == "en" ? data.city_name : selLang == "ar" ? data.city_name_ar : data.city_name_fr });
         });
         this.setState({ city: cities, city_id: cities[0].value })
 
       }
       );
 
       const default_city = this.state.city_id;
       axios.get(`https://mithakh.com/backend/api/v1/services?&city_id=${default_city}`, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(result => { console.log("Services..", result); this.setState({ services: result.data.cityservices }) });
     */
    }

    // this.setState({ city: cities, city_id: cities[0].value })

  }


  componentDidMount() {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function (event) {
      window.location.replace(
        `https://mithakh.com`
      );
    });
    console.log("Current props..", this.props);
    const customer_id = localStorage.getItem("customer_id");
    const { cookies } = this.props;
    this.setState({ customer_id: cookies.get('customer') });


    const countries = [];
    const cities = [];


    this.setState({ token: localStorage.getItem("token") })
    axios.get("https://mithakh.com/backend/api/v1/countries_web", { headers: { 'Access-Control-Allow-Origin': '*' } }).then(result => {
      console.log("country..", result); result.data.countries.forEach(function (data, idx) {
        countries.push({ 'value': data.country_id, 'label': i18next.language == "en" ? data.country_name : i18next.language == "ar" ? data.country_name_ar : data.country_name_fr });
      });

      this.setState({ country: countries });
    });
    axios.get("https://mithakh.com/backend/api/v1/cities", { headers: { 'Access-Control-Allow-Origin': '*' } }).then(result => {
      result.data.cities.forEach(function (data, idx) {
        cities.push({ 'value': data.city_id, 'label': i18next.language == "en" ? data.city_name : i18next.language == "ar" ? data.city_name_ar : data.city_name_fr });
      });
      this.setState({ city: cities })
      this.setState({ city: cities, city_id: cities[0].value })
    }
    );
    const default_city = this.state.city_id;
    axios.get(`https://mithakh.com/backend/api/v1/services?&city_id=${default_city}`, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(result => { console.log("Services..", result); this.setState({ services: result.data.cityservices }) });

  };


  loadTaskTypes() {
    const { serviceType } = this.state;

    // Fetching data from FaceBook Jest Repo
    Get(`https://mithakh.com/backend/api/v1/tasktypes?&servicetype=${serviceType}`).then(result => console.log("tas k typess..", result));
    /** fetch(
        `https://mithakh.com/backend/api/v1/tasktypes?&servicetype=${serviceType}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOGI0Y2Y5YjI0NzVkYWE5NWFhYzQyYmQzZmYxNzY0NzdhYmY1OGU5ODFjMTk2MTZkNjY2ZTM5ZDIwYjJmZDZhMGUwMmYwZjQ2M2E5NTk0OTUiLCJpYXQiOjE2MDQ5MzE1ODUsIm5iZiI6MTYwNDkzMTU4NSwiZXhwIjoxNjM2NDY3NTg1LCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.mJzMg4c5dasjFlwkOl3KJ6wPkLrk7Eftx1pkKhjJKeO8c9CFSe4T1HwlQlwITe7QSwPLIaODR2mYo8YwTdu6EWswzpd1bEPN492Ob7D1E7Ibg_srIDjM7vs85MH1yGFmwzFgrNjhOE6h9LiN3JXF3bS_K_vKOEvyqDrZZMwm1bywbLLyMcflS6NuQt4Yq-_9fXFKXoaoV3AZmEp4WsMiSETpMbmRtXnR-q5R9lSj1CJaxhdu8TzQ-5FQnCtQQZcfX0UVYaHqzwr7keUk4iYYfZpKTt0UuZASaNE_J4EH-x-VzoS08JthWrcwWDjbljeUFA1ZlBbobepF23Hls6m0v3zdL1VsL74-hOyfZsQDpBn3PUMmIi-BcNR22ICHmhGxgkhKI8WIRiD1pIwQk9akKr1MuJi04ewLoNojaC2xWUiVAZgZuTuG8lUYkpYBzNRudzq4DpS9HOfSjIju5-vI7yfV6VMC0924oqyEdoFfSZIESXyL7duaNlP_FuNuI02_8cK9X6FTleyUJ61HCRm0JtM9H_uHvw4-EPmiEruhbH8m8KlAbuvaUz0nKR8j1W5i-tgOVAUMf2pJ81_srlVtSv2DOdb1Fr3K7NCygCBuCHadGNYSX94UlK5thWsk5nivjl4pSUP8aK4wuqVgbJIal7qpWC29I8HAIv5whAFnaWw",
          })
        }
      )
        .then(res => res.json())
        .then(response => 
       
          this.setState({ tasktypes: response.tasktypes })
        )
        .catch(error => console.log(error));**/
  }

  render() {

    const token = localStorage.getItem("token");
    const { ajaxLoader, deviceType, isShow, serviceType, message, message1, message2, message4, taskType, tasktypes, country, country_id, city, services, lat, lng, spinnerLoading, show, showModal, tasks, task, selectedService, selecttask, delivery_title, fileSizeError, otpMsg, otpresult, customer_id, showExistOTP, resendmessage1, country_code, support_number, city_id, isShowShare, defaultLat, defaultLang, mapSel, message3 } = this.state;
    console.log("Support number..", support_number);
    console.log("City ID..", city);
    if (token) {
      const lat = localStorage.getItem("lat");
      const lang = localStorage.getItem("lang");


    }

    var today = new Date()
    const hrs = today.getHours();
    // const hrs = 6;
    console.log("Iam second...", hrs);
    const yes_text = i18next.language == "en" ? "Yes" : i18next.language == "ar" ? "نعم" : "Oui";
    return (
      <>
        <div id="loading" class={ajaxLoader ? 'show-loader' : 'hide-loader'} >
          <img id="loading-image" src={ajaxLoaderN} alt="Loading..." />
        </div>


        <div
          aria-live="polite"
          aria-atomic="true"
          className={show}
          style={{
            position: 'relative',


            left: '50%',
            position: 'absolute',
            top: '50%',

            transform: 'translate(-50%, 0px)',
            zIndex: '9999',
            width: '300px',

          }}


        >
          <div className="outerwrapper" class={show ? 'outerwrapper show' : ''} >
            <Toast
              style={{

                top: 0,
                right: 0,
                zIndex: '9999',
                position: 'relative',

              }}
              onClose={() => this.setShow(false)} show={show} delay={2000}
            >
              <Toast.Header>
                <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                <strong className="mr-auto">Message</strong>

              </Toast.Header>
              <Toast.Body>

                <p className="m-0 text-muted text-center request-msg-color">
                  {
                    message1
                  }
                </p>
                {message2 != "" && hrs >= 20 || hrs < 8 ?
                  <p className="m-0 text-muted text-center request-msg-color">
                    {
                      message1 != "" ? message4 : message2
                    }
                  </p>

                  :
                  <p className="m-0 text-muted text-center request-msg-color">
                    {message4}
                  </p>
                }

                <p className="m-0 text-muted text-center request-msg-color request-id-span">
                  {
                    message3
                  }

                </p>
                {/*  <p className="m-0 text-muted text-center share-btn-head">
                          {
                              i18next.language=="en" ? 'Share To Others' :'مشاركة موقنا لصديق اخر'
                           }
                        </p>
                        <p>
                          <div class="share-btns">
                              <SocialMediaButtons />
                          </div>

                        </p>*/}




                <p class="close-btn-request d-block">
                  {message2 != "" && message1 == "" ?
                    hrs >= 20 || hrs < 8 ? <a class="btn btn-info  toast-btn-s" onClick={() => this.continueExe()}>{yes_text}</a> : '' : ''}

                  <a class="btn btn-info ml-3  toast-btn-s" href="#" onClick={() => this.closeToastPopUp()}> {i18next.language == "en" ? 'Close' : i18next.language == "ar" ? 'إغلاق' : 'Fermer'}</a></p>

              </Toast.Body>

            </Toast>
            <div class="reqoverlay"></div>
          </div>
        </div>



        <Container>
          <Row className="my-4 content-home">

            {isShow === "chooseService" && <ChooseServices
              changeComponent={this.changeComponent}
              city={city}
              country={country}
              filterServices={this.filterServices}
              services={services}
              filterCities={this.filterCities}
              city_id={city_id}

              country_id={country_id}
              checkBoxChangeHandler={this.checkBoxChangeHandler}
              checkBoxChangeServiceHandler={this.checkBoxChangeServiceHandler}
              selected={deviceType} defaultService={serviceType} />}
            {isShow === "gettingInfo1" && <GetInfo
              gettingData={this.gettingData}
              changeComponent={this.changeComponent}
              updateLoctaionCord={this.updateLoctaionCord}
              tasks={tasks}
              selectedtask={selecttask}
              selecteDevice={deviceType}
              selectedService={selectedService}
              changeTaskType={this.changeTaskType}
              checkBoxChangeHandler={this.checkBoxChangeHandler}
              sendOTP={this.sendOTP}
              defaultLat={lat}
              mapSel={mapSel}
              defaultLang={lng}
              changearabicToEng={this.changearabicToEng}
              selected={serviceType}
              country_code={country_code} />}
            {isShow === "gettingInfo2" && <GetInfo2
              // demandType={demandType}
              checkBoxChangeHandler={this.checkBoxChangeHandler}
              gettingData={this.gettingData}
              selected={taskType}
              delivery_title={delivery_title}
              onFileChange={this.onFileChange}
              onFileUpload={this.onFileUpload}
              sendRequestForm={this.sendRequestForm}
              taskTypes={tasktypes}
              changeComponent={this.changeComponent}
              onCaptchaChange={this.onCaptchaChange}
              spinnerLoading={spinnerLoading}
              showModal={showModal}
              sendOTP={this.sendOTP}
              reSendOTP={this.reSendOTP}
              fileSizeError={fileSizeError}
              togglePop={this.togglePop}
              verifyOTP={this.verifyOTP}
              otpresult={otpresult}
              otpMsg={otpMsg}
              resendmessage1={resendmessage1}
              showExistOTP={showExistOTP}
              customer_id={customer_id}

              // contactType={contactType}
              // selectedDate={selectedDate}
              dateChangeHandler={this.dateChangeHandler} />}
            <Col md='6' className="align-self-center mt-5 pt-5 mb-4 d-none d-md-block sideImage">
              <img src={sdeImage} alt="" className="img-fluid" />
            </Col>
            {message1 !== "" &&
              <Col sm="12" className="order-4">
                {/* <p className="m-0 text-muted text-center text-dark-green">
                                    {
                                        message1
                                    }
                                  
                                </p>
                            </Col>}
                             {message2 !== "" &&
                            <Col sm="12" className="order-4">
                                <p className="m-0 text-muted text-center second-beow-text">
                                    {
                                      
                                        message2
                                    }
                                </p>*/}




              </Col>}

          </Row>

        </Container>
        <Footer country_code={country_code} support_number={support_number} />
      </>
    );
  }
}

export default withCookies(Home);