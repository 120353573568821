import React, { Component } from "react";
import { Post, Get } from "../../../utilities/axiosRequest";
import { convertDate } from "../../Components/Common/Common";
import ToastBox from "../../Components/ToastBox/ToastBox";
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Table,
    Badge,

    PaginationItem,
    PaginationLink,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup, FormFeedback
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowModalData from "./ShowModalData";
import { maintenanceData } from "./Data";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import Pagination from "react-js-pagination";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Formik } from "formik";
import * as Yup from "yup";
const device = [
    { value: "Mobile", label: "Mobile" }
];
const filter = [
    { label: "Country" },
    { label: "City" },
    { label: "Category" },
    { label: "Company Name" }
]

const validationSchema = Yup.object().shape({
    /*** name: Yup.string()
         .min(5, "Enter more than 5 characters")
         .max(25, "Allow only 25 characters")
         .required("Name is required"),
 **/

});


class Complaints extends Component {

    state = {
        isShowModal: false,
        filteredBy: "",
        filteredBy1: "",
        selectedRecordInfo: null,
        deviceValue: 1,
        startingDate: "",
        endingDate: "",
        sDate: "",
        eDate: "",
        customerId: "",
        deviceId: "",
        customerName: "",
        mobileNumber: "",
        complaintsRecords: [],
        device: [],
        activePage: 1,
        page: 1,
        itemsCountPerPag: 1,
        totalItemsCount: 1,
        country_name: '',
        city_name: '',
        store_id: '',
        request_id: '',
        show: false,
        message: '',
        company_reply: '',



    }

    dateChangeHandler = (date, name) => {

        this.setState({ [name]: date });

    }
    valueChangeHandler = ({ target: { value, name } }) => {
        console.log(name, value)
        this.setState({ [name]: value })

    }
    infoHandler = (selectedInfo) => {
        this.setState({ selectedRecordInfo: selectedInfo })
    }
    modalTogglerHandler = () => {
        this.setState({ selectedRecordInfo: null });
    }

    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber });
        this.setState({ page: pageNumber });


        axios.get('https://mithakh.com/backend/api/v1/store/searchmaintanancereports', {
            params: this.state
        }, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(resp => {
            console.log("Response pagination", resp.data.fixinginfo.data)
            this.setState({ maintenanceRecords: resp.data.fixinginfo.data, totalItemsCount: resp.data.total });

        })


    }

    setShow = (status) => {
        this.setState({ show: status });

    }

    searchRecords = () => {
        const { startingDate, endingDate, deviceValue, customerId, customerName, deviceId, mobileNumber } = this.state;
        console.log("States..", this.state)
        axios.get('https://mithakh.com/backend/api/v1/store/searchmaintanancereports', {
            params: this.state
        }, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(resp => {
            console.log("Fixing info", resp);
            this.setState({ maintenanceRecords: resp.data.fixinginfo.data, totalItemsCount: resp.data.total });

        }

        );
    }



    componentDidUpdate(prevProps, prevState) {

        console.log("props...", prevProps.cstatusid)

        console.log("props...", this.props.statusid)
        if (prevProps.cstatusid !== this.props.cstatusid) {
            Get(`https://mithakh.com/backend/api/v1/store/complaints?statusid=${this.props.cstatusid}`, {

            }).then(resp => {

                this.setState({ complaintsRecords: resp.complaints, totalItemsCount: resp.total, store_id: resp.store_id });

            }

            );
        }
    }
    componentDidMount(props) {
        console.log('$status', this.props)
        const status = [];

        const service = [];
        const store_id = localStorage.getItem("store_user_id");
        Get(`https://mithakh.com/backend/api/v1/store/more-info?&store_id=${store_id}`).then(result => {

            if (result.storeinfo.length > 0) {
                this.setState({ country_name: result.storeinfo[0].country_name, city_name: result.storeinfo[0].city_name });
            }
        })


        const default_city = 1;
        Get(`https://mithakh.com/backend/api/v1/services?&city_id=${default_city}`).then(result => {
            if (result.cityservices) result.cityservices.forEach(function (data, idx) {
                service.push({ 'value': data.service_id, 'label': data.service_title });
            });
            this.setState({ device: service });
            console.log("Services...", result.cityservices)
        });


        // Get(`https://mithakh.com/backend/api/v1/store/fixing-status`).then(result => this.setState({fixingstatus:result.fixingstatus})); 
        Get("https://mithakh.com/backend/api/v1/store/fixing-status").then(result => {
            result.fixingstatus.forEach(function (data, idx) {
                status.push({ 'value': data.req_status_id, 'label': data.title });
            });

            this.setState({ fixingstatus: status });
        });

        Get(`https://mithakh.com/backend/api/v1/store/complaints?statusid=${this.props.cstatusid}`, {

        }).then(resp => {

            this.setState({ complaintsRecords: resp.complaints, totalItemsCount: resp.total, store_id: resp.store_id });

        }

        );

    }

    submitHandler = (values, resetForm) => {
        const { request_id, replyTxt } = values;
        Post("https://mithakh.com/backend/api/v1/store/reply-complaint", { request_id: request_id, replyTxt: replyTxt }).then(result => {

            if (result.result == "success") {
                this.setState({ isShowModal: false })
                this.setState({ message: 'Replied successfully.', show: true })
            }

        });
        setTimeout(
            () => window.location.reload(),
            3000
        );

    };

    changeComplaintStatus = (status, request_id) => {

        Post("https://mithakh.com/backend/api/v1/store/change-company-complaint-status", { request_id: request_id, status: status }).then(result => {

            if (result.result == "success") {
                this.setState({ isShowModal: false })
                this.setState({ message: 'Replied successfully.', show: true })
            }
            setTimeout(
                () => window.location.reload(),
                3000
            );

        });


    };


    render() {
        const { t } = this.props;
        const {
            deviceValue,
            complaintsRecords,
            selectedRecordInfo,
            startingDate,
            endingDate,
            customerId,
            deviceId,
            customerName,
            filteredBy,
            filteredBy1,
            mobileNumber,
            country_name,
            city_name,
            isShowModal, device, totalItemsCount, request_id, message, show, company_reply } = this.state;

        return (
            <>
                <ToastBox show={show} message={message} setShow={this.setShow} />
                <Modal isOpen={isShowModal} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ isShowModal: false })}>{t('Reply')}</ModalHeader>
                    <ModalBody >
                        <Formik
                            initialValues={{

                                replyTxt: "",
                                request_id: request_id,
                                replyTxt: company_reply

                            }}
                            validationSchema={validationSchema}
                            onSubmit={
                                (values, { resetForm }) => this.submitHandler(values, resetForm)
                            }>
                            {({
                                errors,
                                values,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit
                            }) => (
                                <>
                                    <Row>
                                        <Col md="12" sm="12" className="mb-2">
                                            <Row>
                                                <Col sm="4" className="font-weight-bold" style={{ lineHeight: "2em" }}>
                                                    {t('Do you have complaint')}
                                                </Col>
                                                <Col sm="8">

                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.replyTxt && touched.replyTxt}
                                                            placeholder={t('replyTxt')}
                                                            value={values.replyTxt}
                                                            onChange={handleChange("replyTxt")}
                                                            name="replyTxt"
                                                            type="textarea"
                                                            onBlur={handleBlur("replyTxt")}
                                                            maxlength="100"
                                                            className="BoxShadow pl-3" />
                                                        <FormFeedback>{touched.replyTxt && errors.replyTxt}</FormFeedback>
                                                    </FormGroup>


                                                </Col>
                                                <Col sm="4">
                                                    <Button className="RequestBtn d-flex" style={{ justifyContent: 'center' }} onClick={handleSubmit}>
                                                        {t('Send')}

                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )
                            }
                        </Formik>
                    </ModalBody>
                </Modal>

                <Col md="12" xs="12" className="mb-2">

                    <div className="mb-4">
                        <Table className="mb-2 table-responsive-md" style={{ borderBottom: "1px solid #ff5900" }}>
                            <thead>
                                <tr style={{ backgroundColor: "#73C0F1", color: "white" }}>
                                    <th>{t('Complaint Id')}</th>
                                    <th>{t('Request Id')}</th>
                                    <th>{t('Complaint')}</th>
                                    <th>{t('Compliant Date')}</th>
                                    <th>{t('Reply')}</th>
                                    <th>{t('Reply Date')}</th>
                                    <th>{t('Status')}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    complaintsRecords.map((p, ind) => (
                                        <tr key={ind} onClick={() => this.infoHandler(p)}>
                                            <td>{p.complaint_id}</td>
                                            <td>{p.request_id}</td>
                                            <td>{p.customer_msg}</td>
                                            <td>{p.complaint_date}</td>
                                            <td><button name="" id="" onClick={() => this.setState({ isShowModal: true, request_id: p.request_id, company_reply: p.company_reply })} >{t('Reply')}</button></td>
                                            <td>{p.replied_date}</td>
                                            {p.complaint_status == 0 ?
                                                <td> <Button className="d-flex" onClick={() => this.changeComplaintStatus(1, p.request_id)}>{t('Fixed')}</Button></td>
                                                :
                                                <td><Button className="d-flex" onClick={() => this.changeComplaintStatus(0, p.request_id)}>{t('Not Fixed')}</Button></td>
                                            }

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={5}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}
                        />




                    </div>
                </Col>
            </>
        );
    }
}
export default withTranslation()(Complaints);
