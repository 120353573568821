import React from "react";
import { Row, Col } from "reactstrap";
export default function showImageModalData({ showModal, img_link }) {
    return (
        <section className="BoxShadow p-4">

            <Row>
                <Col md="12" sm="12" xs="12">
                    <img src={`https://mithakh.com/backend/public/img/${img_link}`} />

                </Col>

            </Row>
        </section>
    );
};